import "./Frog.css";

const Frog = () => {
  const pos = { x: 0, y: 0 };

  const setMousePosition = (x, y) => {
    pos.x = (x / window.innerWidth).toFixed(2);
    pos.y = (y / window.innerHeight).toFixed(2);

    document.documentElement.style.setProperty("--x", pos.x);
    document.documentElement.style.setProperty("--y", pos.y);
  };

  document.onmousemove = (evt) => {
    setMousePosition(evt.clientX, evt.clientY);
  };
  return (
    <div className="treehouse-frog">
      <div className="left-eye"></div>
      <div className="right-eye"></div>
      <div className="mouth"></div>
    </div>
  );
};
export default Frog;
