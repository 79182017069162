import "./App.css";
import Frog from "./Frog";

function App() {
  return (
    <div className="App">
      <Frog />
      <h2>Myopic Frog - Coming soon</h2>
    </div>
  );
}

export default App;
